import React from 'react'
import { Link as Link_ } from 'gatsby-plugin-intl'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import { useThemeContext } from 'context/ThemeContext'
import CustomImage from 'components/foundations/Image/CustomImage'
import SEO from 'components/elements/SEO/SEO'

const Link = styled(Link_)`
  color: #444444;
  text-decoration: none;
  &:hover {
    color: #d66728;
  }
`

export default function TrainingList() {
  const TrainingQuery = Object.values(
    useStaticQuery(graphql`
      query TrainingLQuery {
        allSitePage(filter: { path: { regex: "/^/servizi/formazione/" } }) {
          nodes {
            path
          }
        }
      }
    `).allSitePage.nodes,
  )

  const productURIs = TrainingQuery.map((paths) => Object.values(paths).toString())

  function splitName(string) {
    return string.split('/')[3]
  }

  /*   function capitalise(string) {
    return string
      .toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ')
  } */

  const { isMobile } = useThemeContext()

  return (
    <>
      <SEO title="Formazione" keywords={['SEO0', 'SEO1', 'SEO2']} />
      <section className="consulting closer">
        <h3 className="title">Formazione</h3>
        <CustomImage filename={'training.jpg'} />
        <div className="consulting-grid">
          <div className="consulting-grid-list">
            <p style={{ marginBottom: '0.5rem' }}>CATEGORIE</p>
            <div className="separator" />
            <GridList cols={isMobile ? 1 : 1} cellHeight="auto" className="gridListConsulting">
              {productURIs.map((productURL) => (
                <GridListTile className="single-consulting" key={splitName(productURL)}>
                  <Link to={productURL}>
                    <Typography className="consulting-info" component="p">
                      {splitName(productURL).replace(/-/g, ' ')}
                    </Typography>
                  </Link>
                  <hr />
                </GridListTile>
              ))}
            </GridList>
          </div>
          <div className="consulting-grid-content">
            <p className="consulting-content-text">
              <strong>LA FORMAZIONE È LA CHIAVE DEL SUCCESSO!</strong>
            </p>
            <p className="intro-products">I nostri 5 buoni motivi:</p>
            <ul className="intro-products">
              <li>
                <b>
                  <em>Economicità</em>&nbsp;
                </b>
                = da un’analisi di mercato riusciamo ad essere tra i players più economici del mercato;
              </li>
              <li>
                <b>
                  <em>Semplicità</em>&nbsp;
                </b>
                = i nostri corsi sono caratterizzati da grande immediatezza e semplicità e sono ricchi di immagini e
                fumetti esplicativi. I nostri corsi si fanno seguire con curiosità e passione.
              </li>
              <li>
                <b>
                  <em>Competenza</em>&nbsp;
                </b>
                = i nostri corsi sono stati redatti da professionisti della Safety che lavorano sul campo
                quotidianamente al fianco dei lavoratori da oltre 25 anni per tantissime aziende piccole, medie e grandi
                su tutto il territorio nazionale
              </li>
              <li>
                <b>
                  <em>Autorizzazione</em>&nbsp;
                </b>
                = i nostri corsi sono gestiti ed erogati in collaborazione con GESFOR, <b>Ente di Formazione</b> che
                opera dal 1998 nella Formazione e nell’Orientamento Professionale nonché nelle politiche del lavoro, con
                finalità educative e sociali, anche <b>APL</b>, iscritta all’Albo Informatico delle Agenzie del Lavoro
                che svolgono attività di Intermediazione ed autorizzata anche per l’attività di somministrazione,
                accreditata per l’erogazione dei Servizi per il Lavoro sul territorio nazionale accreditato
                regionalmente e con sedi fisiche di supporto in <b>30 città italiane</b>.
              </li>
              <li>
                <b>
                  <em>Controllo</em>&nbsp;
                </b>
                = i nostri corsi sono controllati da un Comitato Scientifico costituito da professionisti della Safety,
                della Medicina del lavoro, della Formazione con esperienza ultraventennale.
              </li>
            </ul>
            <p className="consulting-content-text">
              I nostri corsi online rappresentano una proposta formativa unica in Italia. Grazie alla migliore didattica
              e la più potente tecnologia, Gesfor accompagna il discente durante l&apos;intero percorso di realizzazione
              delle sue esigenze e dei suoi obiettivi professionali. I corsi in autoapprendimento consentono di
              iscriversi in qualsiasi momento dell’anno e di organizzare lo studio secondo i propri ritmi fruendo di un
              ricco pacchetto di contenuti e attività (videolezioni, slide, dispense, questionari interattivi,
              approfondimenti).
              <br /> <br />
              Gesfor progetta e organizza formazione su tutto il territorio nazionale, sia in aula che attraverso
              strumenti tecnologici: per questo motivo l’e-learning viene visto dalla nostra azienda come un metodo di
              studio innovativo utile a ottimizzare il tempo dedicato alla formazione, in base alle esigenze quotidiane
              che dettano ritmi sempre meno compatibili con una formazione in loco, e contemporaneamente non ne
              penalizzano la qualità.
              <br /> <br />
              La nostra piattaforma ci permette in qualsiasi momento di monitorare il singolo percorso formativo di
              ciascun utente, sì da visualizzare il punto esatto che il discente sta seguendo. Così facendo abbiamo la
              possibilità di curare in tutto e per tutto il reale apprendimento del partecipante, e ogni esigenza a
              questo connessa.
              <br /> <br />
              La piattaforma ci permette di accertare che il discente sia realmente davanti al computer: il sistema per
              permettere di procedere nello scorrimento delle slides ha bisogno di un clic sul play.
              <br /> <br />
              Il discente potrà sempre e in qualsiasi momento andare a rivedere una lezione già svolta, per approfondire
              ulteriormente i concetti già visti, anche quando ha terminato del tutto il corso: ciò permette un
              apprendimento reale, e non puramente nominale, dei concetti visti.
              <br /> <br />
              Si possono allegare file di slide in formato PDF, gif, jpeg, così che le dispense della singola lezione
              siano scaricabili dal discente e possano rimanere in suo possesso.
              <br /> <br />
              Il corso può essere seguito tramite personal PC, Tablet, Ipad, Cellulari Smartphone.
              <br /> <br />
            </p>
            <br />
            <Link to="/corsi">
              <span className="btn product-btn">Acquista i corsi online</span>
            </Link>
          </div>
        </div>
      </section>
    </>
  )
}
